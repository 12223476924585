var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { clearTerminal, showContact, showEducations, showFolders, showNotAvailableAction, showSkills, } from './action';
export var DATA = {
    skills: {
        title: 'Skills',
        command: 'skills',
        children: ['JavaScript', 'TypeScript', 'Angular', 'RxJs', 'NgRx', 'React'],
    },
    contact: {
        title: 'Contact',
        command: 'contact',
        children: [
            { title: 'email', value: 'mahyar.shabani@outlook.com', icon: '' },
            { title: 'phone', value: '+989124021270', icon: '' },
            { title: 'location', value: 'Tehran, Iran', icon: '' },
            {
                title: 'linkedin',
                value: 'https://www.linkedin.com/in/mahyarshabani',
                icon: '',
            },
            { title: 'github', value: 'https://github.com/Mahyar-sh', icon: '' },
        ],
    },
    education: {
        title: 'Education',
        command: 'education',
        children: [
            {
                title: 'Bachelor of Science (B.S.)',
                field: 'Mechanical Engineering',
                university: 'Azad University',
                location: 'Tehran, Iran',
                date: '2013 - 2018',
            },
        ],
    },
    workExperience: {
        title: 'Work Experience',
        command: 'work-experience',
        children: [
            {
                jobTitle: 'Senior Front-end Developer(Remote)',
                location: 'Cologne, Germany',
                company: 'Ampada GmbH',
                time: '10/2021 - Present',
                tasks: [
                    'Planning and documenting technical specifications for features or system design.',
                    'Wrote unit and integration tests checked by SonarQube as a continuous integration service to meet 70% code coverage using automated testing tools like Jasmine and Karma.',
                    'Implemented voice chat between users using WebRTC and a socket Node.js service',
                ],
            },
            {
                jobTitle: 'Front-end Developer',
                location: 'Tehran, Iran',
                company: 'Farabi Brokerage Co',
                time: '07/2020 - 10/2021',
                tasks: [
                    'Developed more than 50 UI components using StoryBook used throughout the development team.',
                    "Reimplemented the company's main website using Angular Universal and reworked on SEO optimization.",
                    'Collaborated with product designing team to improve user experience despite the numerous features the website has.',
                ],
            },
        ],
    },
};
var dataCommands = function () {
    var cmds = [];
    for (var dataKey in DATA) {
        cmds.push(DATA[dataKey].command);
    }
    console.log({ cmds: cmds });
    return cmds;
};
export var EXECUTIONS = {
    skills: showSkills,
    education: showEducations,
    contact: showContact,
    ll: showFolders,
    la: showFolders,
    ls: showFolders,
    clear: clearTerminal,
    default: showNotAvailableAction,
};
export var commands = __spreadArray(['ll', 'ls', 'la', 'clear'], dataCommands(), true);
