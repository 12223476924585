import { isArrowKeys, isBackspace, isCtrlC, isEnter, isTab, promptTerm, } from './util';
import { SHELL_PROMPT } from './terminal.conf';
import { commands, EXECUTIONS } from './my-data';
export var bootstrapTerminal = function (state) {
    var term = state.term, cmd = state.cmd, disabledTyping = state.disabledTyping;
    term.open(document.getElementById('terminal'));
    term.write(SHELL_PROMPT);
    term.onKey(function (_a) {
        var key = _a.key, ev = _a.domEvent;
        var printable = 
        // @ts-ignore
        !ev.altKey && !ev.altGraphKey && !ev.ctrlKey && !ev.metaKey && !isTab(ev);
        if (isEnter(ev)) {
            execute();
            // promptTerm(term);
            cmd = '';
        }
        else if (isBackspace(ev)) {
            if (!!cmd) {
                term.write('\b \b');
                cmd = cmd.slice(0, -1);
            }
        }
        else if (isCtrlC(ev)) {
            term.write('^C');
            promptTerm(term);
        }
        else if (isTab(ev)) {
            autoComplete(ev);
        }
        else if (isArrowKeys(ev)) {
            handleArrowKeys(ev, key);
        }
        else if (printable && !disabledTyping) {
            cmd += key;
            term.write(key);
        }
    });
    function autoComplete(ev) {
        if (!!cmd) {
            var filteredCommands = commands.filter(function (command) {
                return command.startsWith(cmd);
            });
            if (filteredCommands.length > 0) {
                if (filteredCommands.length === 1) {
                    var add = filteredCommands[0].substring(cmd.length);
                    cmd = cmd + add;
                    term.write(add);
                }
                else {
                    term.getSelectionPosition();
                }
            }
        }
    }
    function execute() {
        (EXECUTIONS === null || EXECUTIONS === void 0 ? void 0 : EXECUTIONS[cmd]) ? EXECUTIONS[cmd](term) : EXECUTIONS.default(term, cmd);
        console.log({ cmd: cmd });
        // switch (cmd) {
        //   case 'clear':
        //     term.clear();
        //     break;
        //   case 'la':
        //   case 'll':
        //   case 'ls':
        //     newLine(term);
        //     showFolders();
        //     break;
        //   default:
        //     newLine(term);
        //     term.write(`${cmd}: command not found`);
        //     newLine(term);
        //     term.write('Available commands: ');
        //     newLine(term);
        //     commands.forEach((cmd) => {
        //       term.write(cmd);
        //       newLine(term);
        //     });
        // }
    }
    function handleArrowKeys(ev, key) {
        // TODO: handle arrows
        return;
        // const cursorX = term.buffer.normal.cursorX;
        // console.log({
        //   cursorX,
        //   cmdLength: cmd.length,
        //   SHELL_PROMPT_LENGTH,
        // });
        // if (isArrowUp(ev)) {
        //   return;
        // } else if (isArrowDown(ev)) {
        //   return;
        // } else if (isArrowRight(ev)) {
        //   if (cursorX >= SHELL_PROMPT_LENGTH + cmd.length) {
        //     return;
        //   } else {
        //     // cmd += key;
        //     term.write(key);
        //   }
        // } else if (isArrowLeft(ev)) {
        //   if (cursorX <= SHELL_PROMPT_LENGTH) {
        //     return;
        //   } else {
        //     // cmd += key;
        //     term.write(key);
        //   }
        // }
    }
};
