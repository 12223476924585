var State = /** @class */ (function () {
    function State() {
        this._cmd = '';
        this.disabledTyping = false;
    }
    Object.defineProperty(State.prototype, "cmd", {
        get: function () {
            return this._cmd;
        },
        set: function (val) {
            this._cmd = val;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(State.prototype, "term", {
        get: function () {
            return this._term;
        },
        enumerable: false,
        configurable: true
    });
    State.prototype.initializeTerm = function (terminal) {
        this._term = terminal;
    };
    return State;
}());
export { State };
