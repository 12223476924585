import { SHELL_PROMPT } from './terminal.conf';
export var isEnter = function (ev) {
    return ev.keyCode === 13 || ev.key === 'Enter';
};
export var isBackspace = function (ev) {
    return ev.keyCode === 8 || ev.key === 'Backspace';
};
export var isCtrlC = function (ev) {
    return (ev.keyCode === 67 || ev.key === 'c') && ev.ctrlKey;
};
export var isTab = function (ev) {
    return ev.keyCode === 9 || ev.key === 'Tab';
};
export var isArrowUp = function (ev) {
    return ev.keyCode === 38 || ev.key === 'ArrowUp';
};
export var isArrowDown = function (ev) {
    return ev.keyCode === 40 || ev.key === 'ArrowDown';
};
export var isArrowLeft = function (ev) {
    return ev.keyCode === 37 || ev.key === 'ArrowLeft';
};
export var isArrowRight = function (ev) {
    return ev.keyCode === 39 || ev.key === 'ArrowRight';
};
export var isArrowKeys = function (ev) {
    return (isArrowUp(ev) || isArrowDown(ev) || isArrowLeft(ev) || isArrowRight(ev));
};
export var promptTerm = function (term) {
    term.write('\r\n' + SHELL_PROMPT);
};
export var newLine = function (term) {
    term.write('\r\n');
};
export var type = function (term, text, time) {
    if (time === void 0) { time = 25; }
    return new Promise(function (resolve, reject) {
        var arr = text.split('');
        var intervalId = setInterval(function () {
            term.write(arr.shift());
            if (arr.length <= 0) {
                clearInterval(intervalId);
                resolve();
            }
        }, time);
    });
};
