var USERNAME = 'mahyar.shabani';
var USERNAME_COLOR = '\x1b[32m';
// const SHELL_PROMPT_USER = '\x1b[32m' + USERNAME;
var COLON = ':';
var COLON_COLOR = '\x1b[37m';
// const COLON = ':';
// const COLON = '\x1b[37m:';
var CURRENT_DIR = '~';
var CURRENT_DIR_COLOR = '\x1b[34m';
var DOLLAR_SIGN = '$ ';
var DOLLAR_SIGN_COLOR = '\x1b[37m';
export var SHELL_PROMPT_LENGTH = (USERNAME +
    COLON +
    CURRENT_DIR +
    DOLLAR_SIGN).length;
export var SHELL_PROMPT = USERNAME_COLOR +
    USERNAME +
    COLON_COLOR +
    COLON +
    CURRENT_DIR_COLOR +
    CURRENT_DIR +
    DOLLAR_SIGN_COLOR +
    DOLLAR_SIGN;
